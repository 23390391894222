import request from '@/utils/request'

// api地址
const api = {
  all: '/frm/activityTemplate',
  actTempId: '/frm/activityTemplate/',
  save: '/frm/activityTemplate',
  update: '/frm/activityTemplate/update',
  delete: '/frm/activityTemplate/',
  deleteBatch: '/frm/activityTemplate/batch',
  page: '/frm/activityTemplate/page',
  tempContentId: '/frm/activityTemplate/content/',
  tempContentSave: '/frm/activityTemplate/content',
  tempContentUpdate: '/frm/activityTemplate/content/update',
  tempNoticeId: '/frm/activityTemplate/notice/',
  tempNoticeSave: '/frm/activityTemplate/notice',
  tempNoticeUpdate: '/frm/activityTemplate/notice/update'
}

// 活动模板管理

/**
 * 查询所有
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function all(params) {
  return new Promise((resolve, reject) => {
    request.get(api.all, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 查询所有
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function getActTempId(id) {
  return new Promise((resolve, reject) => {
    request.get(api.actTempId + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function page(params) {
  return new Promise((resolve, reject) => {
    request.get(api.page, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 添加或修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function save(data, isUpdate) {
  if (isUpdate) {
    return new Promise((resolve, reject) => {
      request.post(api.update, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      request.post(api.save, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  }
}
/**
 * 根据id删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteById(id) {
  return new Promise((resolve, reject) => {
    request.delete(api.delete + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 批量删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteBatch(data) {
  return new Promise((resolve, reject) => {
    request.delete(api.deleteBatch, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

// 查询活动内容 - content
/**
 * 查询
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function tempContentId(id) {
  return new Promise((resolve, reject) => {
    request.get(api.tempContentId + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 添加或修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function tempContentSave(data, isUpdate) {
  if (isUpdate) {
    return new Promise((resolve, reject) => {
      request.post(api.tempContentUpdate, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      request.post(api.tempContentSave, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  }
}

// 查询活动须知 - notice
/**
 * 查询
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function tempNoticeId(id) {
  return new Promise((resolve, reject) => {
    request.get(api.tempNoticeId + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 添加或修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function tempNoticeSave(data, isUpdate) {
  if (isUpdate) {
    return new Promise((resolve, reject) => {
      request.post(api.tempNoticeUpdate, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      request.post(api.tempNoticeSave, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  }
}
