<!-- 编辑弹窗 -->
<template>
  <div>
    <a-modal
      :width="950"
      :visible="visible"
      :confirm-loading="loading"
      :title="isUpdate ? '修改' : '新建'"
      :body-style="{ paddingBottom: '8px' }"
      @update:visible="updateVisible"
      @ok="save"
    >
      <a-form
        ref="form"
        :model="form"
        :rules="rules"
        :label-col="{ md: { span: 5 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 19 }, sm: { span: 24 } }"
      >
        <a-row :gutter="16">
          <a-col :sm="12" :xs="24">
            <a-form-item label="活动名称:" name="activityName">
              <a-input
                v-model:value="form.activityName"
                placeholder="请输入活动名称"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :sm="12" :xs="24">
            <a-form-item label="活动分类:" name="categoryId">
              <a-select
                allow-clear
                placeholder="请选择活动分类"
                v-model:value="form.categoryId"
              >
                <a-select-option
                  v-for="item in categoryList"
                  :key="item.categoryId"
                  :value="item.categoryId"
                >
                  {{ item.categoryName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :sm="12" :xs="24">
            <a-form-item label="活动时间开启" name="actModule">
              <a-radio-group
                v-model:value="form.actModule"
                default-value="true"
              >
                <a-radio :value="true">开启</a-radio>
                <a-radio :value="false">关闭</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :sm="12" :xs="24">
            <a-form-item label="活动类型:" name="activityType">
              <a-select
                v-model:value="form.activityType"
                placeholder="请选择活动类型"
                allow-clear
              >
                <a-select-option
                  v-for="item in tempTypeList"
                  :key="item.dictDataCode"
                  :value="item.dictDataCode"
                >
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" v-if="form.actModule">
          <a-col :sm="12" :xs="24">
            <a-form-item label="活动开始时间:" name="actStartTime">
              <a-date-picker
                v-model:value="form.actStartTime"
                :show-time="true"
                value-format="YYYY-MM-DD HH:mm:ss"
                class="ud-fluid"
              >
                <template #suffixIcon>
                  <calendar-outlined />
                </template>
              </a-date-picker>
            </a-form-item>
          </a-col>
          <a-col :sm="12" :xs="24">
            <a-form-item label="活动结束时间:" name="actEndTime">
              <a-date-picker
                v-model:value="form.actEndTime"
                :show-time="true"
                value-format="YYYY-MM-DD HH:mm:ss"
                class="ud-fluid"
              >
                <template #suffixIcon>
                  <calendar-outlined />
                </template>
              </a-date-picker>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :sm="12" :xs="24">
            <a-form-item label="活动地址:" name="address">
              <a-input
                v-model:value="form.address"
                placeholder="请输入活动地址"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :sm="12" :xs="24">
            <a-form-item label="地图坐标:" name="coordinate">
              <div>
                <a-button type="primary" @click="coordinate()"
                  >地图选点</a-button
                >
                <span style="margin-left: 10px"
                  >经度: {{ form.longitude }} 纬度: {{ form.latitude }}</span
                >
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :sm="12" :xs="24">
            <a-form-item label="活动主图:" name="activityMainPic">
              <a-upload
                list-type="picture-card"
                v-model:file-list="activityMainPicList"
                @preview="handlePreview"
                :customRequest="
                  ({ file }) => uploadFile(file, 'activityMainPic')
                "
                :remove="removeFile"
              >
                <div v-if="activityMainPicList.length < 1">
                  <plus-outlined />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                :bodyStyle="{ paddingTop: '50px' }"
                @cancel="previewVisible = false"
              >
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </a-form-item>
          </a-col>
          <a-col :sm="12" :xs="24">
            <a-form-item label="活动图片:" name="activityPics">
              <a-upload
                list-type="picture-card"
                v-model:file-list="activityPicsList"
                @preview="handlePreview"
                multiple
                :customRequest="({ file }) => uploadFile(file, 'activityPics')"
                :remove="removeFile"
              >
                <div v-if="activityPicsList.length < 5">
                  <plus-outlined />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                :bodyStyle="{ paddingTop: '50px' }"
                @cancel="previewVisible = false"
              >
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :sm="24" :xs="24">
            <a-form-item
              label="报名须知"
              :labelCol="{ span: 2, offset: 0 }"
              :wrapper-col="{ span: 24, offset: 0 }"
              name="activityContent"
            >
              <tinymce-editor
                id="edit"
                v-model:value="form.activityContent"
                :init="{ height: 400 }"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-divider style="height: 60px" />
        <a-row :gutter="16">
          <a-col :sm="12" :xs="24">
            <a-form-item label="报名时间开启" name="regModule">
              <a-radio-group
                v-model:value="form.regModule"
                default-value="true"
              >
                <a-radio :value="true">开启</a-radio>
                <a-radio :value="false">关闭</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :sm="12" :xs="24">
            <a-form-item label="报名开始时间:" name="startTime" v-if="form.regModule">
              <a-date-picker
                v-model:value="form.startTime"
                :show-time="true"
                value-format="YYYY-MM-DD HH:mm:ss"
                class="ud-fluid"
              >
                <template #suffixIcon>
                  <calendar-outlined />
                </template>
              </a-date-picker>
            </a-form-item>
            <a-form-item label="报名人数" name="limitType">
              <a-radio-group
                v-model:value="form.limitType"
                default-value="无上限"
              >
                <a-radio :value="'无上限'">无上限</a-radio>
                <a-radio :value="'最大人数'">设置最大人数</a-radio>
                <a-radio :value="'男女'">按性别男女</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="费用类型" name="costType">
              <a-radio-group
                v-model:value="form.costType"
                default-value="不收费"
              >
                <a-radio :value="'不收费'">不收费</a-radio>
                <a-radio :value="'统一费用'">统一费用</a-radio>
                <a-radio :value="'男女'">按性别男女</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="是否审核" name="regApply">
              <a-radio-group
                v-model:value="form.regApply"
                default-value="不审核"
              >
                <a-radio :value="'不审核'">不审核</a-radio>
                <a-radio :value="'管理员'">管理员审核</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :sm="12" :xs="24">
            <a-form-item label="报名结束时间:" name="endTime" v-if="form.regModule">
              <a-date-picker
                v-model:value="form.endTime"
                :show-time="true"
                value-format="YYYY-MM-DD HH:mm:ss"
                class="ud-fluid"
              >
                <template #suffixIcon>
                  <calendar-outlined />
                </template>
              </a-date-picker>
            </a-form-item>
            <a-form-item
              v-if="form.limitType === '最大人数'"
              label="人数:"
              name="maxCount"
            >
              <a-input-number
                v-model:value="form.maxCount"
                placeholder="请输入人数"
                @change="changeRatio"
                allow-clear
                style="width: 100%"
              />
            </a-form-item>
            <a-form-item
              :wrapper-col="{ span: 24, offset: 3 }"
              v-if="form.limitType === '男女'"
            >
              <div style="display: flex; height: 30px">
                <a-form-item name="maleCount" label="男生:">
                  <a-input-number
                    v-model:value="form.maleCount"
                    placeholder="请输入男生人数"
                    allow-clear
                    style="width: 120px"
                  />
                </a-form-item>
                <a-form-item name="femaleCount" label="女生">
                  <a-input-number
                    v-model:value="form.femaleCount"
                    placeholder="请输入女生人数"
                    allow-clear
                    style="width: 120px"
                  />
                </a-form-item>
                <div class="flex-box">
                  <a-space>
                    <a
                      href="javascript:;"
                      :style="{ color: form.maxCount ? '' : '#d6d7d9' }"
                      @click="quickSet(1)"
                      >1:1</a
                    >
                    <a
                      href="javascript:;"
                      :style="{ color: form.maxCount ? '' : '#d6d7d9' }"
                      @click="quickSet(2)"
                      >1:2</a
                    >
                  </a-space>
                </div>
              </div>
            </a-form-item>
            <a-form-item
              name="cost"
              v-if="form.costType === '男女'"
              :wrapper-col="{ span: 24, offset: 3 }"
            >
              <div style="display: flex; height: 30px">
                <a-form-item name="maleCost" label="男生">
                  <a-input-number
                    v-model:value="form.maleCost"
                    placeholder="请输入男生活动费用"
                    allow-clear
                    style="width: 120px"
                  />
                </a-form-item>
                <a-form-item name="femaleCost" label="女生">
                  <a-input-number
                    v-model:value="form.femaleCost"
                    placeholder="请输入女生活动费用"
                    allow-clear
                    style="width: 120px"
                  />
                </a-form-item>
                <div class="flex-box"></div>
              </div>
            </a-form-item>
            <a-form-item
              label="活动费用:"
              name="cost"
              v-if="form.costType === '统一费用'"
            >
              <a-input-number
                v-model:value="form.cost"
                placeholder="请输入活动费用"
                allow-clear
                style="width: 100%"
              />
            </a-form-item>

            <a-form-item label="报名权限" name="regAuthor">
              <a-radio-group
                v-model:value="form.regAuthor"
                @change="regAuthorChange"
                default-value="任何人"
              >
                <a-radio :value="'任何人'">任何人</a-radio>
                <a-radio :value="'成员'">成员</a-radio>
                <a-radio :value="'指定人员'">指定人员</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :sm="12" :xs="24" v-if="form.regAuthor === '指定人员'">
            <a-form-item label="指定人员:" name="member">
              <a-select
                mode="multiple"
                v-model:value="form.member"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="(value, index) in memberList"
                  :key="index"
                  :value="value.name"
                  >{{ value.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :sm="12" :xs="24">
            <a-form-item name="participantInfo" label="参与人填写项:">
              <div>
                <a-button type="primary" @click="showFieldSet = true"
                  >设置</a-button
                >
              </div>
              <div class="form-info-list" @click="showFieldSet = true">
                <div
                  class="info"
                  v-for="(item, index) in participantInfoList"
                  :key="index"
                >
                  <span style="color: red; margin-right: 4px" v-if="item.verify"
                    >*</span
                  ><span>{{ item.label }}</span>
                </div>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :sm="24" :xs="24">
            <a-form-item
              name="activityNotice"
              label="报名后展示:"
              :labelCol="{ span: 2.5, offset: 0 }"
              :wrapper-col="{ span: 23, offset: 0 }"
            >
              <tinymce-editor
                id="edit"
                v-model:value="form.activityNotice"
                :init="{ height: 400 }"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <a-modal
      :width="1100"
      :visible="showFieldSet"
      title="设置参与人填写项"
      :body-style="{ paddingBottom: '8px' }"
      @cancel="showFieldSet = false"
      @ok="fieldSetConfirm"
    >
      <fromFieldList
        ref="activityField"
        :formId="form.formId"
        :action="1"
        v-model:value="fieldList"
      ></fromFieldList>
    </a-modal>
    <a-modal
      :width="950"
      :visible="showPostion"
      title="地图选址"
      :body-style="{ paddingBottom: '8px' }"
      @cancel="cancelPosition"
      @ok="setPosition"
    >
      <Map ref="map" v-model:position="position" id="pointAddress" />
    </a-modal>
  </div>
</template>

<script>
import * as activityTemplateApi from '@/api/activity/activityTemplate.js'
import * as activityCategoryApi from '@/api/activity/activityCategory.js'
import * as dictDataApi from '@/api/dict/dictData.js'
import setting from '@/config/setting'
import TinymceEditor from '@/components/TinymceEditor'
import Map from '@/components/map/addressPicker.vue'
import fromFieldList from '@/views/frm/activity/form/field.vue'
function getBase64(pointPhoto) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(pointPhoto)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export default {
  name: 'tempEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: {
    Map,
    TinymceEditor,
    fromFieldList
  },
  data() {
    return {
      uploadUrl: setting.uploadUrl,
      // 表单数据
      form: Object.assign({}, this.data),
      // 编辑弹窗表单验证规则
      rules: {
        activityName: [
          {
            required: true,
            message: '请输入活动名称',
            type: 'string',
            validateTrigger: 'blur'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      activityPicsList: [],
      activityMainPicList: [],
      previewVisible: false,
      previewImage: '',
      showPostion: false,
      position: {},
      // 是否显示字典数组
      isShowList: [],
      // 模板类型数组
      tempTypeList: [],
      // 活动分类
      categoryList: [],
      // 设置参与人填写项是否显示
      showFieldSet: false,
      fieldList: [],
      participantInfoList: []
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.isUpdate = true
        this.resetForm()
        this.form = this.data
        this.getActivityTempContent(this.form.activityId)
        this.getActivityTempNotice(this.form.activityId)
        this.getTemField()
      } else {
        this.form = {}
        this.isUpdate = false
        this.resetForm()
      }
      if (this.form.activityMainPic) {
        this.activityMainPicList[0] = {
          uid: '-10',
          name: 'image.png',
          status: 'done',
          url: this.form.activityMainPic
        }
      }
      if (this.form.activityPics) {
        const picsArr = this.strToArr(this.form.activityPics)
        picsArr.forEach((item, index) => {
          this.activityPicsList.push({
            uid: `-${index + 1}`,
            name: 'image.png',
            status: 'done',
            url: item
          })
        })
      }
      if (this.form.longitude) {
        this.position = {
          fullAddress: this.form.fullCity + this.form.address,
          longitude: this.form.longitude,
          latitude: this.form.latitude
        }
      } else {
        this.position = {}
      }
    }
  },
  mounted() {
    this.resetForm()
    this.queryCategoryList()
    this.queryDataStates()
    this.queryTempTypeStates()
  },
  methods: {
    getTemField() {
      activityTemplateApi.getActTempId(this.form.activityId).then((res) => {
        if (res.code === 0) {
          console.log('getTemFieldRes', res.data)
          this.fieldList = res.data.activityFormDataFields
          this.participantInfoList = res.data.formData.map((item) => {
            item.verify = item.rules.verify
            return item
          })
          this.initSelectMember(res.data.activityUserIdList)
        }
      })
    },
    // 参与人填写项确认
    fieldSetConfirm() {
      this.fieldList = this.$refs.activityField.fieldList
      this.removeIds = this.$refs.activityField.removeIds
      this.participantInfoList = this.fieldList.filter((item) => {
        return item.enable === true
      })
      this.form.activityFormDataFieldDTOS = {
        fieldList: this.fieldList,
        removeIds: this.removeIds
      }
      this.showFieldSet = false
    },
    // 数组转字符串方法
    arrToStr(arr) {
      if (arr.length > 0) {
        const str = arr.join(',')
        return str
      }
    },
    // 字符串转数组方法
    strToArr(str) {
      if (str) {
        return str.split(',')
      }
    },
    async handlePreview(pointPhoto) {
      if (!pointPhoto.url && !pointPhoto.preview) {
        pointPhoto.preview = await getBase64(pointPhoto.originFileObj)
      }
      this.previewImage = pointPhoto.url || pointPhoto.preview
      this.previewVisible = true
    },
    handleCancel() {
      this.previewVisible = false
    },
    resetForm() {
      this.form = {}
      this.form.activityContent = ''
      this.form.activityNotice = ''
      this.activityPicsList = []
      this.activityMainPicList = []
      this.form.formId = 0
      this.form.actModule = true
      this.form.regModule = true
      this.form.limitType = '无上限'
      this.form.regAuthor = '任何人'
      this.form.regApply = '不审核'
      this.form.costType = '不收费'
      this.queryCategoryList()
      this.participantInfoList = []
      if (!this.isUpdate) {
        const data = [
          {
            fieldId: 'name',
            referrerFieldId: null,
            dataType: null,
            placeholder: '请输入姓名',
            label: '姓名',
            type: '单行文本',
            dataScope: null,
            dataSource: null,
            oneKeyPhone: null,
            title: null,
            listData: null,
            verify: true,
            regexpData: null,
            errMsg: '姓名未填写',
            sortNumber: 1,
            minCount: null,
            maxCount: null,
            minDate: null,
            maxDate: null,
            dateDisplay: '年月日',
            defaultValue: null,
            uploadType: '上传设置',
            photoDesc: null,
            sampleGraph: null,
            provice: null,
            miniProgram: null,
            enable: true,
            distinct: false,
            readonly: null,
            hide: null,
            custom: false
          }
        ]
        this.fieldList = data
        this.participantInfoList = data
        this.$nextTick(() => {
          // this.$refs.memberSelect.reload()
        })
      }
    },
    coordinate() {
      this.postion = this.form
      this.showPostion = true
    },
    cancelPosition() {
      this.showPostion = false
    },
    setPosition() {
      const data = this.$refs.map.form
      this.form.longitude = data.longitude
      this.form.latitude = data.latitude
      this.form.address = data.fullAddress
      this.showPostion = false
    },
    // 获取富文本活动内容
    getActivityTempContent(id) {
      if (this.isUpdate) {
        activityTemplateApi
          .tempContentId(id)
          .then((res) => {
            if (res.code === 0) {
              if (res.data) {
                this.form.activityContent = res.data.activityContent
              } else {
                this.form.activityContent = ''
              }
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      }
    },
    // 获取富文本活动须知
    getActivityTempNotice(id) {
      if (this.isUpdate) {
        activityTemplateApi
          .tempNoticeId(id)
          .then((res) => {
            if (res.code === 0) {
              if (res.data) {
                this.form.activityNotice = res.data.activityNotice
              } else {
                this.form.activityNotice = ''
              }
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      }
    },
    removeFile(file) {
      const uid = file.uid
      this.$http
        .delete('/file/remove', {
          path: file.url
        })
        .then((res) => {
          if (res.data.code === 0) {
            if (uid === '-10') {
              this.activityMainPicList = []
              this.form.activityMainPic = ''
            } else {
              if (this.activityPicsList.length > 0) {
                const picsArr = this.activityPicsList.map((item) => {
                  return item.url
                })
                this.form.activityPics = this.arrToStr(picsArr)
              } else {
                this.form.activityPics = ''
              }
            }
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    uploadFile(file, name) {
      console.log('file', file)
      console.log('fileList', this.activityPicsList)
      const formData = new FormData()
      formData.append('file', file)
      const uid = file.uid
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            if (name === 'activityMainPic') {
              this.activityMainPicList = [
                {
                  uid: uid,
                  name: 'image.png',
                  status: 'done',
                  url: res.data.location
                }
              ]
              this.form[name] = res.data.location
            } else if (name === 'activityPics') {
              this.activityPicsList[this.activityPicsList.length - 1] = {
                uid: uid,
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
              if (this.activityPicsList.length > 0) {
                const picsArr = this.activityPicsList.map((item) => {
                  return item.url
                })
                this.form[name] = this.arrToStr(picsArr)
              }
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    initSelectMember(data) {
      this.memberList = data.map((item) => {
        return {
          label: item.name,
          value: item.id
        }
      })
      this.selectMember = data.map((item) => {
        return item.id
      })
      this.memberSelection = data
    },
    /* 保存编辑 */
    save() {
      if(!this.isUpdate){
        this.form.corpId = this.getCorpId()
      }
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          this.form.activityFormDataFieldDTOS = {
            fieldList: this.fieldList,
            removeIds: this.removeIds
          }
          if (!this.isUpdate) {
            this.form.formId = -1
          }
          if (this.form.regAuthor === '指定成员') {
            this.form.activityUserIdList = this.memberSelection.map((item) => {
              return {
                id: item.id,
                name: item.name
              }
            })
          } else {
            this.form.activityUserIdList = []
          }
          activityTemplateApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.resetForm()
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    // 获取分类
    queryCategoryList() {
      activityCategoryApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.categoryList = res.data.filter((item) => item.isShow === 1)
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // /* 查询是否显示类型 */
    queryDataStates() {
      dictDataApi
        .getDictData({ dictCode: 'isShow' })
        .then((res) => {
          if (res.code === 0) {
            this.isShowList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // /* 查询模板类型 */
    queryTempTypeStates() {
      dictDataApi
        .getDictData({ dictCode: 'tempType' })
        .then((res) => {
          if (res.code === 0) {
            this.tempTypeList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
